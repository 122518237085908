import React, { useEffect, useState } from "react";
import axios from "axios";

const App = () => {
  const [users, setUsers] = useState([]); // Stocke les utilisateurs
  const [error, setError] = useState(null); // Gère les erreurs

  useEffect(() => {
    // Appeler l'API pour récupérer les utilisateurs
    axios
      .get("http://77.37.51.10/api/users") // URL de ton backend
      .then((response) => {
        // Extraire les utilisateurs de la propriété "member"
        setUsers(response.data.member);
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des utilisateurs :", error);
        setError("Impossible de récupérer les données du serveur.");
      });
  }, []);

  return (
    <div>
      <h1>Liste des Utilisateurs</h1>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <ul>
        {users.map((user) => (
          <li key={user.id}>
            {user.name || user.Name} {/* Affiche 'name' ou 'Name' */}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default App;
